import React from 'react'
import PageWrapper from '../components/pagewrapper'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import {graphql} from 'gatsby'

import {PhotoSwipe} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import Footer from '../components/footer'
import {Helmet} from 'react-helmet'


var listitems = "ksjd"

class Mainprojects extends React.Component{
  constructor(props) {
    super(props)

    this.iconlist = this.props.data.drupal.nodeQuery.entities.map((item)=> {
      return (
        {
          src: item.fieldWorkimage.entity.thumbnail.imageFile.childImageSharp.resize.src,
          w: 1200,
          h: 900,
          title: item.fieldCategory.entity.name,
          id: item.fieldWorkimage.entity.thumbnail.imageFile.id
        }
      )
    })




    this.state= {

      photoitems:null,
      options:null,
      isOpen: false,
    }
  }



  handleClose = () => {
   this.setState({isOpen:false})
}

handleImageViewer = (id, category) => {
  let index = 0;

  let list = this.iconlist.filter((item) => item.title === category)
  list.forEach((item,i) => {
    if(item.id === id) {
      index = i
    }
  })
  this.setState({
    photoitems:list,
    options:{index:index, history:false},
    isOpen:true
  })

}

  componentDidMount() {
    var $ = window.$

    setTimeout(()=> {
      var $container = $('.portfolio-wrap');
          $container.isotope({
              itemSelector: '.masonry-item',
              transitionDuration: '1s',
    originLeft: true,
    stamp: '.stamp',
          });

          $container.isotope({ filter: '*' });


      $('.masonry-filter li').on('click',function() {
          var selector = $(this).find("a").attr('data-filter');
          $('.masonry-filter li').removeClass('active');
          $(this).addClass('active');
          $container.find(".hidden").removeClass("hidden");
          $container.isotope({ filter: selector });
          counter = initShow;

          loadMore(initShow);
          return false;
      });
        $container.after(`<div class="margin-top-30 text-center"><button id="load-more" class="site-button btn-effect margin-top-5">${this.props.pageContext.langcode === 'EL' ? "ΠΕΡΙΣΣΟΤΕΡΑ" : "LOAD MORE"}</button></div>`);

      //****************************
    // Isotope Load more button
    //****************************
    var initShow = 12; //number of images loaded on init & onclick load more button
    var counter = initShow; //counter for load more button
    var iso = $container.data('isotope'); // get Isotope instance


    loadMore(initShow); //execute function onload

    function loadMore(toShow) {







                $container.find(".hidden").removeClass("hidden");








      var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
        return item.element;
      });

      $(hiddenElems).addClass('hidden');

      $container.isotope('layout');



      //when no more to load, hide show more button
      if (hiddenElems.length == 0) {
        $("#load-more").hide();
      }
    else {
        $("#load-more").show();
      };

    }

    //append load more button


    //when load more button clicked
    $("#load-more").click(function() {

      // if ($('#filters').data('clicked')) {
      //   //when filter button clicked, set initial value for counter
      //   counter = initShow;
      //   $('#filters').data('clicked', false);
      // } else {
      //   counter = counter;
      // };

      counter = counter + initShow;
         console.log(counter)
      loadMore(counter);
    });



  }, 300)



  }

  render() {



    var newarr={};
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen.links
    }
    else {
      menu = this.props.pageContext.menuel.links
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({path:item.entityUrl.path, id:item.entityLanguage.id.toUpperCase()})
    })

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
  var breadpath = this.props.location.pathname.split(/[/]/)
  breadpath.shift()

  breadpath.forEach((item,i) => {
    var value = null;
    var path = null;
    // console.log(item,i,loc[0])

    if(i === 0) {
      if(item === 'en' || item === 'el') {
        value = 'HOME';
        path=`/${item}`
      }
      else {
        value=item;
        path=`/${item}`
      }
      loc.push({value: value, path: path})
    }
    else {
      value=item;
      path=`${loc[i-1].path}/${item}`
      loc.push({value: value, path: path})
    }





  })


       return (
         <div>
           <Helmet>
             {this.props.pageContext.metainfo.map((item,i) => {
       if(item.key === 'title') {
         return (
           <title key={i}>{item.value}</title>
         )
       }
       else if(item.key === 'canonical') {
         return (
           <link key={i} rel="canonical" href={this.props.path} />
         )
       }

       else if(item.key === 'description') {
         return (
           <meta key={i} name="description" content={item.value}/>
         )
       }

       else {
         return null;
       }
     })}
           </Helmet>

          <PageWrapper>
          <PhotoSwipe isOpen={this.state.isOpen} items={this.state.photoitems} options={this.state.options} onClose={this.handleClose}/>
        <Header info={this.props.data.drupal.contactInfo} logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu} langcontainer={langpath} path={this.props.path}/>
        <Breadcrumb background={this.props.pageContext.background} title={this.props.pageContext.title} path={loc}/>
        <div className="section-full p-tb80 inner-page-padding">

           <div className="container">

             <div className="section-head">
   <div className="mt-separator-outer separator-center">
     <div className="mt-separator">
        {this.props.pageContext.langcode === 'EN'? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Project</h2>
        : <h2 className="text-uppercase sep-line-one ">Τα <span className="font-weight-300 text-primary">Εργα</span> μας</h2> }


     </div>
   </div>
 </div>
                 {/*<!-- PAGINATION START --> */}
                 <div className="filter-wrap p-b30 text-center">
                     <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">

                         <li className="active"><a data-filter="*" href="#" data-hover="All">All</a></li>
                           {this.props.data.drupal.nodeQuery.entities.filter((item) => {

                             if(!item.fieldCategory.entity || !item.fieldWorkimage.entity) {
                               return false;
                             }
                             else {
                               var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis
                               if(newarr[nameclass]) {
                                 return false;

                               }
                               else {
                                 newarr[nameclass]=nameclass
                                 return true;
                               }
                             }

                           }).map((item) => {


                                     var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis



                               return (
                                 <li className="">
                      <a href="#" data-filter={`.${nameclass}`} data-hover={item.fieldCategory.entity.name}>{item.fieldCategory.entity.name}</a>
                                 </li>

                               )


                          })}

                     </ul>
                 </div>
                 {/*<!-- PAGINATION END --> */}

                 {/*<!-- GALLERY CONTENT START --> */}
                  <div className="portfolio-wrap mfp-gallery work-grid row clearfix" >
                     {/*<!-- COLUMNS 1 --> */}
                     {this.props.data.drupal.nodeQuery.entities.filter((item)=> {
                       if(!item.fieldCategory.entity || !item.fieldWorkimage.entity) {
                         return false;
                       }
                       else {
                         return true;
                       }
                     }).map((item,i) => {


                         var nameclass = item.fieldCategory.entity.name.replace(/\s/g, '').toLowerCase() //HACK gia na bgalei to keno ap tis klaseis
                                return (
                                  <div key={i} className={`masonry-item ${nameclass}  col-md-4 col-sm-6 m-b30`} >
                                      <div className="mt-box image-hover-block hoverable">
                                        <div className="mt-thum-bx">
                                            <img className="croped" src={item.fieldWorkimage.entity.thumbnail.imageFile.childImageSharp.fixed.src} alt={item.fieldWorkimage.entity.thumbnail.alt}/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                             <h4 className="mt-tilte m-b10 m-t0">{item.fieldCategory.entity.name.toUpperCase()}</h4>
                                             <p className="m-b0">{item.body? item.body.summary: null}</p>
                                        </div>





                                              <a onClick={() => this.handleImageViewer(item.fieldWorkimage.entity.thumbnail.imageFile.id, item.fieldCategory.entity.name) }>

                                              </a>

                                      </div>
                                  </div>
                                )




                     })}






                  </div>
                 {/*<!-- GALLERY CONTENT END --> */}


           </div>


         </div>










<Footer social={this.props.data.drupal.social} logourl={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu}  info={this.props.data.drupal.contactInfo}/>

         </PageWrapper>
        </div>
       )
  }






}

export default Mainprojects;


export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
    footerLogo:nodeById(id: "25") {
     ... on DRUPAL_NodeLogo {

       fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
     }
   }
    headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
   }
 }
    contactInfo: nodeById(id: "26", language: $langcode) {
   title
   ... on DRUPAL_NodeContactInfo {
     fieldAddress
     fieldEmail
     fieldPhone
   }
 }
 social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
    entities {
      ... on DRUPAL_NodeSocialLinks {

        fieldIcon
        fieldLink
      }
    }
  }
nodeQuery(filter: {conditions: [{field: "type", value: "workservices", language: $langcode}]}, limit: 500, sort: [{field: "field_simantiko"}, {field: "field_category"}]) {
 entities(language: $langcode) {
        ... on DRUPAL_NodeWorkservices {
          body {
            summary
          }
          entityLabel
          fieldWorkimage {
              entity {
                thumbnail {
                  url
                  alt
                  imageFile {
                    id
                 childImageSharp {
                   fixed(width: 350, height: 300, quality: 80) {
                     src
                   }
                   resize(width: 1200, height: 900, fit: COVER, quality: 80) {
                     src
                   }
                 }
                 publicURL
               }
                }
              }
            }
          fieldCategory {
            entity {
              name
            }
          }
        }
      }
}
}

}
`
